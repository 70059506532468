﻿(function () {

    var exceptionCap = 5;
    var exceptionsCaptured = 0;

    var pageLoadDate = new Date().valueOf();

    var onErrorCallback = window.onerror || function () { };

    window.onerror = function (msg, url, line, col, error) {

        try {
            if (typeof msg === "object") {
                msg = JSON.stringify(msg);
            }

            logErrorToServer(msg, url, line, col, error);
        } catch (e) {
            // Do nothing
        }

        onErrorCallback(msg, url, line, col, error);
    };

    function logErrorToServer(msg, url, line, col, error) {

        if (++exceptionsCaptured > exceptionCap) {
            return;
        }

        var pageAgeSeconds = ((new Date().valueOf() - pageLoadDate) / 1000).toString(); 

        var xhr = new XMLHttpRequest();

        xhr.open('POST', '/Error/CaptureClientSideException');

        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        xhr.send(
            'Message=' + encodeURIComponent(msg) +
            '&Error=' + encodeURIComponent(error) +
            '&Url=' + encodeURIComponent(url) +
            '&Line=' + encodeURIComponent(line) +
            '&Col=' + encodeURIComponent(col) +
            '&PageAgeSeconds=' + pageAgeSeconds);
    }

    window.setEventContext = function (attributes) {
        // may be assigned by the New Relic server agent via injected inline script.
        var newRelicAgent = window.newrelic; 
        if (newRelicAgent) {
            for (var key in attributes) {
                var value = attributes[key];
                if (value !== undefined) {
                    // adds an attribute to all subsequent Ajax requests
                    newRelicAgent.setCustomAttribute(key, value, false);
                }
            }
        }
    }
})();